<template>
  <div class="wap-user-order">
    <headerBar :title="$t('wo-de-ding-dan-0')" @back="back"></headerBar>

    <div class="tab-list">
      <Tabs color="#9d48f7" @click="changeTab" v-model="tabName">
        <Tab
          v-for="(item, i) in tabList"
          :key="i"
          :title="item.name"
          :name="item.status"
        ></Tab>
      </Tabs>
    </div>

    <div class="user-order-list">
      <List
        v-if="dataList.length > 0"
        v-model="loading"
        :finished="finished"
        :finished-text="i18n.t('common.noMore')"
        @load="onLoad"
      >
        <div class="list" v-for="(item, i) in dataList" :key="i">
          <div class="line flex-center-between">
            <div class="label">{{ $t('ding-dan-hao') }}</div>
            <div class="value flex-center">
              {{ item.OrderNo }}
              <i
                class="el-icon-copy-document copy-icon"
                @click="copyData(item)"
              ></i>
            </div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t('xin-xi-0') }}</div>
            <div class="info">
              <div>
                {{ item.OrderProduct.length }}
                {{ $t('ge-shang-pin') }}
              </div>
              <div class="gray">
                {{ $t('lai-zi') }}
                {{ item.Shop.Name }}
              </div>
            </div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t('jin-e') }}</div>
            <div class="value primary">$ {{ item.TotalAmount }}</div>
          </div>
          <div class="line flex-center-between">
            <div class="label">{{ $t('cao-zuo') }}</div>
            <div class="value primary">
              <div @click="toDetail(item)">{{ $t('cha-kan-xiang-qing') }}</div>
              <div @click="toPay(item)" v-if="item.Status == 0">
                {{ $t('li-ji-fu-kuan') }}
              </div>
              <div
                @click="toCancel(item)"
                v-if="item.Status == 0 || item.Status == 1"
              >
                {{ $t('qu-xiao-ding-dan') }}
              </div>
              <div
                @click="toEvalute(item)"
               
              >
                {{ $t('li-ji-ping-jia') }}
                 <!-- v-if="item.Status == 3 || item.Status == 4" -->
              </div>
            </div>
          </div>
        </div>
      </List>
    </div>

    <Popup v-model="showPopup" @cancel="cancel" position="bottom" round>
      <div class="password-popup">
        <div class="title">
          {{ $t('qing-shu-ru-jiao-yi-mi-ma') }}
          <i @click="cancel" class="el-icon-close"></i>
        </div>
        <PasswordInput :focused="showPopup" :value="password"></PasswordInput>
        <NumberKeyboard
          :show="showPopup"
          v-model="password"
          @input="doPay"
          @hide="cancel"
          :maxlength="6"
        ></NumberKeyboard>
      </div>
    </Popup>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import {
  List,
  Tabs,
  Tab,
  Dialog,
  Popup,
  NumberKeyboard,
  PasswordInput
} from 'vant'
import Clipboard from 'clipboard'
import { getOrderList, cancelOrder, orderPay } from '@/api/user'
export default {
  components: {
    headerBar,
    List,
    Tabs,
    Tab,
    Popup,
    NumberKeyboard,
    PasswordInput
  },
  data() {
    return {
      isInit: false,
      loading: false,
      finished: false,
      showPopup: false,
      password: '',
      tabName: '',
      form: {
        status: ''
      },
      page: {
        current: 1,
        size: 10
      },
      totalPage: 1,
      dataList: [],
      orderId: '',
      pageType: ''
    }
  },
  computed: {
    tabList() {
      return [
        {
          name: this.$t('quan-bu'),
          status: ''
        },
        {
          status: '0',
          name: this.$t('dai-fu-kuan')
        },
        {
          status: '1',
          name: this.$t('dai-fa-huo')
        },
        {
          status: '2',
          name: this.$t('dai-shou-huo')
        },
        {
          status: '3',
          name: this.$t('dai-ping-jia')
        },
        {
          status: '7,8',
          name: this.$t('tui-kuan-shou-hou')
        }
      ]
    }
  },
  mounted() {
    let status = this.$route.query.status
    this.pageType = this.$route.query.type
    this.form.status = status
    this.tabName = status
    this.init()
  },
  methods: {
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    back() {
      if (this.pageType === 'init') {
        this.$router.push({
          name: 'wapMy'
        })
      } else {
        this.$router.go(-1)
      }
    },
    onLoad() {
      this.page.current += 1
      this.init(true)
    },
    init(isAdd) {
      getOrderList({
        current: this.page.current,
        pageSize: this.page.size,
        Status: this.form.status ? parseFloat(this.form.status) : null
      }).then((res) => {
        if (res.data.Items) {
          let list = res.data.Items
          if (isAdd) {
            this.dataList = this.dataList.concat(list)
          } else {
            this.dataList = list
          }
          this.totalPage = res.data.Pagination.totalPage
          if (this.totalPage <= this.page.current) {
            this.finished = true
          }
        }
        this.isInit = true
        this.loading = false
      })
    },
    changeTab(status) {
      this.form.status = status
      this.page.current = 1
      this.init()
    },
    copyData(data) {
      const clipboard = new Clipboard('.copy-icon', {
        text: () => data.OrderNo
      })
      this.$toast(this.$t('fu-zhi-cheng-gong'))
    },
    toDetail(data) {
      sessionStorage.setItem('orderDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'wapUserOrderDetail'
      })
    },
    toCancel(data) {
      Dialog.confirm({
        title: this.$t('que-ren'),
        message: this.$t('que-ren-qu-xiao-ding-dan')
      })
        .then(() => {
          cancelOrder({
            ID: data.ID
          }).then((res) => {
            if (res.code == 0) {
              this.$toast(this.$t('qu-xiao-cheng-gong'))
              this.page.current = 1
              this.init()
            } else {
              this.$toast(res.msg)
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    toPay(data) {
      this.orderId = data.ID
      this.showPopup = true
    },
    cancel() {
      this.showPopup = false
    },
    doPay() {
      setTimeout(() => {
        if (this.password.length == 6) {
          orderPay({
            OrderID: this.orderId,
            Password: parseInt(this.password)
          }).then((res) => {
            if (res.code == 0) {
              this.$toast(this.$t('zhi-fu-cheng-gong'))
              this.showPopup = false
              this.page.current = 1
              this.init()
            } else {
              this.$toast(res.msg)
            }
          })
        }
      }, 300)
    },
    toEvalute(data) {
      this.$router.push({
        name: 'wapOrderEvalute',
        query: {
          id: data.ID,
          productId: data.OrderProduct[0].BusinessProductId
        }
      })
    }
  }
}
</script>